import { formatPriceWithDecimal } from 'src/utils/formatter';

export const getColumnDataManager = (): any => {
  return [
    {
      title: 'Sale',
      dataIndex: 'staff',
      key: 'staff',
      align: 'center',
    },
    {
      title: 'Tổng số phút gọi',
      dataIndex: 'totalTime',
      key: 'totalTime',
      align: 'center',
      render: (cell: any) => {
        return <div>{formatPriceWithDecimal(cell || 0, true)}</div>;
      },
    },
    {
      title: 'Số phút gọi ra',
      dataIndex: 'timeOutgoing',
      key: 'timeOutgoing',
      align: 'center',
      render: (cell: any) => {
        return <div>{formatPriceWithDecimal(cell || 0, true)}</div>;
      },
    },
    {
      title: 'Số phút gọi vào',
      dataIndex: 'timeIncoming',
      key: 'timeIncoming',
      align: 'center',
      render: (cell: any) => {
        return <div>{formatPriceWithDecimal(cell || 0, true)}</div>;
      },
    },
    {
      title: 'Số cuộc gọi ra',
      dataIndex: 'totalCall',
      key: 'totalCall',
      align: 'center',
      render: (cell: any) => {
        return <div>{formatPriceWithDecimal(cell || 0, true)}</div>;
      },
    },
    {
      title: 'Số cuộc gọi gặp',
      dataIndex: 'totalSuccess',
      key: 'totalSuccess',
      align: 'center',
      render: (cell: any) => {
        return <div>{formatPriceWithDecimal(cell || 0, true)}</div>;
      },
    },
    {
      title: 'Tỷ lệ kết nối thành công',
      dataIndex: 'successRate',
      key: 'successRate',
      align: 'center',
      render: (cell: any) => {
        return <div>{formatPriceWithDecimal(cell || 0, true)}</div>;
      },
    },
  ];
};
