/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
// import useCheckSpeedNetwork from 'src/hooks/useCheckSpeedNetwork';

type Props = {
  handleCheckingNetwork: (value: boolean) => void;
};

const PingColor = ({ handleCheckingNetwork }: Props) => {
  const [colorPing, setColorPing] = useState('status-green');
  // const [isPoorNetwork, setIsPoorNetwork] = useState<boolean>(true);
  // const numberSpeedMb = useCheckSpeedNetwork();

  // useEffect(() => {
  //   if (+numberSpeedMb < 3) {
  //     setIsPoorNetwork(false);
  //     setColorPing('status-red');
  //   } else if (+numberSpeedMb >= 3 && +numberSpeedMb <= 10) {
  //     setIsPoorNetwork(true);
  //     setColorPing('status-yellow');
  //   } else {
  //     setIsPoorNetwork(true);
  //     setColorPing('status-green');
  //   }

  //   // console.log(`Network Speed: ${numberSpeedMb} mbps`);
  // }, [numberSpeedMb]);

  // useEffect(() => {
  //   handleCheckingNetwork(isPoorNetwork);
  // }, [isPoorNetwork]);

  return <div className={`btn-calling-status ${colorPing}`}></div>;
};

export default PingColor;
