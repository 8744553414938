import { Button } from 'antd';
import React from 'react';

type Props = {
  onClick: () => void;
};

const ErrorBox: React.FC<Props> = ({ onClick }) => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h2>Opps!... Không tìm thấy data tương ứng</h2>
      <Button
        type="primary"
        onClick={onClick}
      >
        Quay lại
      </Button>
    </div>
  );
};

export { ErrorBox };
