import React from "react";
import { Typography } from "antd";

type Props = {
  title: string;
  textStyles?: React.CSSProperties;
};

const TitleColumn = (props: Props) => {
  const { title, textStyles } = props;
  return (
    <Typography.Text ellipsis={true} style={{ ...styleTitle, ...textStyles }}>
      {title}
    </Typography.Text>
  );
};

export { TitleColumn };

const styleTitle: React.CSSProperties = {
  flexWrap: "wrap",
  wordWrap: "break-word",  
  fontSize: "0.8vw",
  color: "white",
  width: "100%",
  textAlign: "center",
};
