import { Button, Spin } from 'antd';
import React from 'react';
import SelectSaleMan from 'src/components/SelectSaleMan';
import { isNullOrEmpty } from 'src/utils/stringUtils';
import { State } from '.';
import './style.scss';

type Props = {
  UIstate: State;
  staffs: any;
  onSelectSaleMan: (value: string) => void;
  onMoveData: () => void;
};

const ContentMoveData: React.FC<Props> = (props) => {
  const { UIstate, staffs, onSelectSaleMan, onMoveData } = props;

  const styleSelect = { width: '100%', marginBottom: 16, marginTop: 16 };

  return (
    <Spin spinning={UIstate.loadingFetchMoveData}>
      <div className="modal-move-data">
        <p className="text text-bold">Chuyển data</p>
        <p className="text text-small">
          (Chỉ chuyển data có trạng thái khác L8.1)
        </p>
        <div style={{ textAlign: 'center', marginTop: 12 }}>
          Số data yêu cầu chuyển:
          <span className="text text-bold">
            {UIstate.selectedPhones.length} data.
          </span>{' '}
          Số data được quyền chuyển:{' '}
          <span className="text text-bold">
            {UIstate.transferPhones.length} data.
          </span>{' '}
          Vui lòng chọn saleman để tiếp tục
        </div>
        <SelectSaleMan
          style={styleSelect}
          treeData={staffs}
          value={UIstate.selectedStaff}
          onChange={onSelectSaleMan}
          placeholder={'Chọn saleman'}
          treeCheckable={false}
          treeDefaultExpandAll={staffs.length === 1}
        />
        <Button
          onClick={onMoveData}
          disabled={isNullOrEmpty(UIstate.selectedStaff)}
          loading={UIstate.loadingMove}
          type="primary"
        >
          Chuyển Data
        </Button>
      </div>
    </Spin>
  );
};

export default ContentMoveData;
