import { deleteParamsNotUsing, getQueryString } from 'src/utils/stringUtils';
import APIService from '../utils/apiServices';

class UserServices extends APIService {
  async login(data) {
    return await this.request('POST', `auth/sign-in`, data);
  }

  async changePassword(data) {
    return await this.request('POST', `profile/change-password`, data);
  }

  async getProfile() {
    return await this.request('GET', `profile`, {});
  }

  async getListAccounts(params: AccountFilterPrams) {
    deleteParamsNotUsing(params);
    const url = getQueryString('account/search', params);
    return await this.request('GET', url);
  }

  async getListManager(query?: any) {
    return await this.request('GET', `account/managers${query || ''}`, {});
  }

  async getListLevelSale(query) {
    return await this.request('GET', `/level-sale${query || ''}`, {});
  }

  async createAccount(data) {
    return await this.request('POST', `account`, data);
  }

  async editAccount(userId, data) {
    return await this.request('PUT', `account/${userId}`, data);
  }

  async resetPasswordAccount(userId: number) {
    // reset to abc12345@
    return await this.request('PUT', `/account/${userId}/reset-password`, {});
  }

  uploadImg(file: any) {
    return this.request(
      'POST',
      '/account/upload-avatar',
      {
        file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }
}
const userServices = new UserServices();
export default userServices;
