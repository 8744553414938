import React from 'react';
import InteractionItem from './InteractionItem';
import './style.scss';

type Props = {
  data?: InteractionHistory[];
};

const InteractionHistory: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <div className="bg-[#fff]">
      <div className="block__header">
        <h2>LỊCH SỬ TƯƠNG TÁC</h2>
      </div>
      <div
        style={{
          overflowX: 'hidden',
          overflowY: 'scroll',
          height: '85vh',
          paddingBottom: '10vh',
        }}
      >
        {data?.map((item: InteractionHistory, index: number) => (
          <InteractionItem
            item={item}
            key={index + 'history' + item.interactionTime}
          />
        ))}
      </div>
    </div>
  );
};

export default InteractionHistory;
