import React from 'react';

type Props = {
  phoneNumber: string;
};
const PopupHeader: React.FC<Props> = ({ phoneNumber }) => {
  return (
    <p>
      Tạo yêu cầu đăng ký gói cước cho SĐT:{' '}
      <span
        style={{
          color: '#1777FF',
          fontWeight: 'bold',
        }}
      >
        {phoneNumber}
      </span>
    </p>
  );
};

export default PopupHeader;
