import React from 'react';

type Props = {
  data: ObjectData[];
  selected: string;
  handleOptionChange: (id: string) => void;
};

function SelectOptions({ data, selected, handleOptionChange }: Props) {
  const onChange = (event: any) => {
    handleOptionChange(event.target.value);
  };

  return (
    <div className="flex pt-3 pb-3 justify-evenly">
      {data.map((option: ObjectData) => (
        <label
          key={option.id}
          className="flex items-center gap-1"
          style={{
            fontWeight: selected === option.id ? 'bold' : '',
          }}
        >
          <input
            type="radio"
            name="option"
            value={option.id}
            checked={selected === option.id}
            onChange={onChange}
          />
          {option.name}
        </label>
      ))}
    </div>
  );
}

export default SelectOptions;
