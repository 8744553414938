import { message } from 'antd';
import React, { useEffect } from 'react';
import AppContext from 'src/context/AppContext';
import talkTimeServices from 'src/services/talktime.service';
import { RESPONSE_CODE, ROLES_LIST_MANAGER } from 'src/utils/constants';
import { isNullOrEmpty } from 'src/utils/stringUtils';

type StateProps = {
  filter: {
    fromDate: string;
    staffs: string;
    toDate: string;
  };
  data: TalkTimeProps[] | null;
  loading: boolean;
  isRefresh: boolean;
};

export const useTalkTime = () => {
  const { appState } = React.useContext(AppContext);

  const [state, setState] = React.useState<StateProps>({
    filter: {
      fromDate: '',
      staffs: '',
      toDate: '',
    },
    data: [],
    loading: false,
    isRefresh: false,
  });

  useEffect(() => {
    if (state?.isRefresh) {
      getData();
    }
  }, [state?.isRefresh]);

  useEffect(() => {
    getData();
  }, []);

  const formatData = (data: TalkTimeRes) => {
    if (isNullOrEmpty(data)) {
      return null;
    }
    const elementToMove = 'total';
    if (appState?.profile?.roles[0] === ROLES_LIST_MANAGER[1]?.id) {
      return Object.values(data);
    }
    let temp: TalkTimeProps[] = Object.values(data);

    const existIndex = temp?.findIndex(
      (item: any) => item?.staff === elementToMove
    );
    if (existIndex !== -1) {
      const element = temp?.filter(
        (item: any) => item?.staff === elementToMove
      );
      temp.splice(existIndex, 1);
      temp.splice(0, 0, element[0]);
      temp[0].staff = 'TOTAL';

      return temp;
    } else {
      return null;
    }
  };

  const getData = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));

    const res = await talkTimeServices.getData({
      fromDate: state?.filter?.fromDate,
      toDate: state?.filter?.toDate,
      staffs:
        appState?.profile?.roles[0] === ROLES_LIST_MANAGER[1]?.id
          ? appState?.profile?.username
          : state?.filter?.staffs,
    });

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        loading: false,
        isRefresh: false,
        data: formatData(res?.data),
      }));
    } else {
      message.error(res?.data ?? 'Có lỗi xảy ra');
      setState((prev) => ({
        ...prev,
        loading: false,
        isRefresh: false,
      }));
    }
  };

  const onChangeStaff = (value: string) => {
    setState((prev) => ({
      ...prev,
      isRefresh: true,
      filter: {
        ...prev.filter,
        staffs: value,
      },
    }));
  };

  const onChangeFromDate = (value: string) => {
    setState((prev) => ({
      ...prev,
      isRefresh: true,
      filter: {
        ...prev.filter,
        fromDate: value,
      },
    }));
  };

  const onChangeToDate = (value: string) => {
    setState((prev) => ({
      ...prev,
      isRefresh: true,
      filter: {
        ...prev.filter,
        toDate: value,
      },
    }));
  };

  return {
    state,
    onChangeStaff,
    onChangeFromDate,
    onChangeToDate,
  };
};
