import { Table } from 'antd';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTalkTime } from 'src/hooks/useTalkTime';
import { useWindowSize } from 'src/utils/customHooks';
import { Filter } from './Filter';
import { getColumnDataManager } from './getCollumn';

function TalkTime() {
  const { state, onChangeStaff, onChangeFromDate, onChangeToDate } =
    useTalkTime();

  const windowSize = useWindowSize();

  useEffect(() => {
    styleTotalTd();
  }, [state?.data]);

  const styleTotalTd = () => {
    const trElements = document.querySelectorAll(
      '.ant-table-row.ant-table-row-level-0'
    );
    trElements.forEach((tr: any) => {
      const totalTd = tr.querySelector('.ant-table-cell');
      if (totalTd && totalTd?.textContent?.trim() === 'TOTAL') {
        tr.style.fontWeight = 'bold';
        tr.style.backgroundColor = '#eeeeee';
      }
    });
  };

  return (
    <div className="pt-[10px]">
      <Helmet>
        <title>Theo dõi TalkTime</title>
        <meta name="description" content={'TalkTime'} />
      </Helmet>
      <Filter
        onChangeStaff={onChangeStaff}
        onChangeFromDate={onChangeFromDate}
        onChangeToDate={onChangeToDate}
      />
      <Table
        loading={state?.loading}
        rowKey={'id'}
        className="ml-4 mr-4"
        dataSource={state?.data ?? []}
        columns={getColumnDataManager()}
        pagination={false}
        scroll={{ x: 1000, y: windowSize.height - 150 }}
      />
    </div>
  );
}

export default TalkTime;
