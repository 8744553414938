import { Space } from 'antd';
import React from 'react';

type Props = {
  index: number;
  total: string;
  percent: string;
  isShowNumber?: boolean;
  isShowRatio?: boolean;
};

const styleSpace = {
  with: 70,
  height: 30,
  paddingBottom: 20,
};

export const ItemColumn: React.FC<Props> = (props) => {
  const {
    index,
    total,
    percent,
    isShowNumber = true,
    isShowRatio = true,
  } = props;

  if (!isShowNumber || !isShowRatio) {
    return (
      <span
        style={index ? { fontSize: 14 } : { fontWeight: 600, fontSize: 15 }}
      >
        {isShowNumber && total}
        {isShowRatio && percent}
      </span>
    );
  }
  return (
    <Space direction="vertical" size={0} style={styleSpace}>
      <span
        style={index ? { fontSize: 14 } : { fontWeight: 600, fontSize: 15 }}
      >
        {total}
      </span>
      <p
        style={{
          color: '#1890ff',
          fontSize: 11,
          position: 'relative',
          bottom: 4,
        }}
      >
        {percent.replace('.', ',')}
      </p>
    </Space>
  );
};
