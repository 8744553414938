import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { validateamountTopup } from 'src/utils/helper';
import { formatted } from 'src/utils/stringUtils';

type Props = {
  phoneNumber?: string;
  handleFinishTopupTransfer: (values: any, onClose: () => void) => void;
  loadingTransfer: boolean;
};

export const RecordTopupOutside = ({
  phoneNumber,
  handleFinishTopupTransfer,
  loadingTransfer,
}: Props) => {
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
  };

  const handleFinishTransfer = (values: any) => {
    let params = {
      msisdn: phoneNumber,
      amountTopupOutSide:
        (values?.amountTopupOutSide as string)?.replace(/\D/g, '') || 0,
      discountPercentTopupOutSide: values?.discountPercentTopupOutSide || 0,
    };
    handleFinishTopupTransfer?.(params, onClose);
  };

  return (
    <div>
      <p className="text-center mb-[24px]">
        Khách hàng: <strong className="text-[#1777FF]">{phoneNumber}</strong>
      </p>
      <div className="w-[300px] mt-3 mb-2">
        <Form form={form} onFinish={handleFinishTransfer}>
          <div>
            <Form.Item
              name={'amountTopupOutSide'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập số tiền nạp',
                  validator: validateamountTopup,
                },
              ]}
            >
              <Input
                placeholder="Nhập số tiền nạp"
                allowClear
                onChange={(e: { target: { value: string } }) => {
                  form.setFieldsValue({
                    amountTopupOutSide: formatted(e.target?.value),
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              name="discountPercentTopupOutSide"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tỉ lệ chiết khấu',
                  validator: validateamountTopup,
                },
              ]}
            >
              <Input placeholder="Nhập tỉ lệ chiết khấu (%)" allowClear />
            </Form.Item>
          </div>
          <div className="flex justify-center">
            <Button type="primary" htmlType="submit" loading={loadingTransfer}>
              Xác nhận
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
