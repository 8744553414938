import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import './style.scss';

type Props = {
  historyPacks: any;
};

// eslint-disable-next-line react/prop-types
const HistoryPacks: React.FC<Props> = ({ historyPacks }) => {
  const columns: ColumnsType<any> = [
    {
      title: 'Mã gói',
      dataIndex: 'packCode',
      key: 'packCode',
      width: '20%',
    },
    {
      title: 'Tên gói cước',
      dataIndex: 'packName',
      key: 'packName',
      width: '30%',
    },
    {
      title: 'Thời gian đăng ký',
      dataIndex: 'startDate',
      key: 'startDate',
      width: '25%',
      render: (cell: any) => moment(cell).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: 'Thời gian hết hạn',
      dataIndex: 'endDate',
      key: 'endDate',
      width: '25%',
      render: (cell: any) => moment(cell).format('DD-MM-YYYY HH:mm:ss'),
    },
  ];

  return (
    <>
      <div className="content-basic-info">
        <div className="text-title-css">Lịch sử đăng ký gói cước</div>
        {historyPacks ? (
          <Table
            columns={columns}
            style={{ marginTop: -40 }}
            dataSource={historyPacks}
            locale={{ emptyText: 'Không có kết quả phù hợp' }}
            pagination={{
              size: 'small',
              position: ['topRight'],
              defaultPageSize: 10,
              pageSizeOptions: [10],
              showSizeChanger: false,
            }}
          />
        ) : (
          <>Không tìm thấy Lịch sử đăng ký gói cước</>
        )}
      </div>
    </>
  );
};

export default HistoryPacks;
