import { Tag, Modal, Table, Button } from 'antd';
import { arrStringToString } from '../../utils/stringUtils';
import moment from 'moment';
import { SUGGESTED_PACKAGES } from '../../utils/constants';

type Props = {
  modalStatus: boolean;
  handleCancel: any;
  dataDetail: any;
};

function ModalInfo(props: Props) {
  const { modalStatus, handleCancel, dataDetail } = props;

  const columnsUserInfo = [
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (cell: any) => {
        return <div className="bold">{cell || '--'}</div>;
      },
    },
    {
      title: 'Loại thuê bao',
      dataIndex: 'type',
      key: 'type',
      render: (cell: any) => {
        return cell || '--';
      },
    },
    {
      title: 'Ngày kích hoạt',
      dataIndex: 'activeDate',
      key: 'activeDate',
      render: (cell) => {
        return cell
          ? moment(cell, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY | HH:mm:ss')
          : '---';
      },
    },
    {
      title: 'Tài khoản chính (VNĐ)',
      dataIndex: 'amount',
      key: 'amount',
      render: (cell) => {
        return cell ? cell.toLocaleString() : '--';
      },
    },
    {
      title: 'Chủ thuê bao',
      dataIndex: 'customer',
      key: 'customer',
      render: (cell) => {
        return cell || '--';
      },
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      render: (cell) => {
        return cell || '--';
      },
    },
  ];

  const columnsHistoryPackages = [
    {
      title: 'Mã gói',
      dataIndex: 'packCode',
      key: 'packCode',
    },
    {
      title: 'Tên gói cước',
      dataIndex: 'packName',
      key: 'packName',
    },
    {
      title: 'Thời gian đăng ký',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (cell) => {
        return cell
          ? moment(cell, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY | HH:mm:ss')
          : '---';
      },
    },
    {
      title: 'Thời gian hết hạn',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (cell) => {
        return cell
          ? moment(cell, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY | HH:mm:ss')
          : '---';
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (cell) => {
        return (
          <Tag color={cell === 'Bình thường' ? 'green' : 'volcano'}>{cell}</Tag>
        );
      },
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      render: (cell) => {
        return cell || '---';
      },
    },
  ];

  const columnsSuggestedPackages = [
    {
      title: 'Loại',
      dataIndex: 'name',
      key: 'name',
      render: (cell) => {
        return <div className="bold">{cell || '--'}</div>;
      },
    },
    {
      title: 'Danh sách gói cước',
      dataIndex: 'listPackage',
      key: 'listPackage',
      render: (_cell, row) => {
        const listPackages = dataDetail?.suggestedPackages
          ? dataDetail?.suggestedPackages[row?.id]
          : [];
        let text = arrStringToString(listPackages);
        return text || '--';
      },
    },
  ];

  return (
    <Modal
      title={`Thông tin thuê bao ${dataDetail?.phoneNumber}`}
      visible={modalStatus}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Đóng
        </Button>,
      ]}
      width={1500}
      style={{ top: 20 }}
    >
      {dataDetail && (
        <div className="custom-empty">
          <Table
            rowKey={'id'}
            dataSource={[dataDetail]}
            columns={columnsUserInfo}
            pagination={{
              hideOnSinglePage: true,
            }}
          />

          <div className="txt-title txt-blue pdbt-10 pdt-10">
            Lịch sử đăng ký gói cước
          </div>
          <Table
            rowKey={'id'}
            dataSource={
              Array.isArray(dataDetail?.historyPackages)
                ? dataDetail?.historyPackages
                : []
            }
            columns={columnsHistoryPackages}
            pagination={{
              hideOnSinglePage: true,
              total: Array.isArray(dataDetail?.historyPackages)
                ? dataDetail?.historyPackages.length
                : 0,
              pageSize: 5,
              size: 'small',
            }}
          />

          <div className="txt-title txt-blue pdbt-10 pdt-10">
            Danh sách gói cước có thể đăng ký
          </div>
          <Table
            rowKey={'id'}
            dataSource={SUGGESTED_PACKAGES}
            columns={columnsSuggestedPackages}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </div>
      )}
    </Modal>
  );
}

export default ModalInfo;
