import { padStart } from 'lodash';
import React, { useEffect, useState } from 'react';

const CountUp = () => {
  const [second, setSecond] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setSecond((prev: number) => {
        return prev + 1;
      });
    }, 1000);
  }, []);

  let minutes = Math.floor(second / 60);
  let seconds = second - minutes * 60;
  return (
    <p className={'txt-normal blue bold-500 pdl-10'}>
      {padStart(String(minutes), 2, '0')}:{padStart(String(seconds), 2, '0')}
    </p>
  );
};

export default CountUp;
