import { Button, Space } from "antd";
import React from "react";
import "./style.scss";

type Props = {
  profile?: DataDividedProfile;
};

const Filters: React.FC<Props> = ({ profile }) => {
  return (
    <div id="data-divided-filters">
      <Space>
        {profile?.updatedLevelSales?.map((item: string, index: number) => (
          <Button
            type="text"
            block
            className={"button-active"}
            key={"update" + index}
          >
            {item}
          </Button>
        ))}
        {profile?.remainingLevelSales?.map((item: string, index: number) => (
          <Button type="text" block key={"remaining" + index}>
            {item}
          </Button>
        ))}
      </Space>
    </div>
  );
};

export default Filters;
