import { useEffect, useState } from 'react';

type Props = {
  time: number;
  onCallback: () => void;
  isSetTime: boolean;
};

const useCountDown = (props: Props) => {
  const [second, setSecond] = useState(1);

  useEffect(() => {
    if (second < 1) {
      props.onCallback();
    }
  }, [second]);

  useEffect(() => {
    setInterval(() => {
      setSecond((prev: number) => {
        if (prev > 0) {
          return prev - 1;
        }
        return 0;
      });
    }, 1000);
  }, []);

  let minutes = Math.floor(second / 60);
  let seconds = second - minutes * 60;

  useEffect(() => {
    if (props.isSetTime) setSecond(props.time);
  }, [props.isSetTime]);

  return {
    seconds,
    minutes,
  };
};

export default useCountDown;
