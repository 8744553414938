export const TYPE_EDIT = 'TYPE_EDIT';
export const TYPE_CREATE = 'TYPE_CREATE';

export const STATUS_SUCCESS = 200;
export const STATUS_ERROR = 400;

export const TURN_UP = 'TURN_UP';
export const TURN_UP_TKC = 'TURN_UP_TKC';

export const DEFAULT = 'DEFAULT';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';

export const PAGE_SIZE = 20;
