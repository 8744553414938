import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { formatPrice } from 'src/utils/formatter';
import { formatPriceChart } from 'src/utils/stringUtils';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

type Props = {
  className: string;
  width: number;
  ChartName?: string;
  dataLeft?: DataChart[] | null;
  dataRight?: DataChart[] | null;
  height: number;
  dataAxisY?: string[];
  empty?: boolean;
  fontSize?: number;
  angle?: number;
};

type YAxisProps = {
  name?: string;
  symbol?: { fill: string };
};

type DataFinalProps = { x: number; y: number }[];

const TwoTypeChart = ({
  className,
  width,
  ChartName,
  dataLeft,
  dataRight,
  height,
  dataAxisY,
  empty,
  fontSize = 10,
  angle = 0,
}: Props) => {
  const [dataFinal, setDataFinal] = useState<DataFinalProps[]>([]);
  const [dataYAxis, setDataYAxis] = useState<YAxisProps[]>([]);
  const getNameAndColor = () => {
    let result = [];
    result?.push(
      dataLeft?.[0]?.name
        ? {
            name: dataLeft?.[0]?.name,
            symbol: { fill: '#1663AB' },
          }
        : {}
    );
    result?.push(
      dataRight?.[0]?.name
        ? {
            name: dataRight?.[0]?.name ?? '',
            symbol: { fill: '#F9AB40' },
          }
        : {}
    );

    return result;
  };

  const getName = (color: string) => {
    const existsItem = getNameAndColor()?.filter((item) => {
      return item?.symbol?.fill === color;
    });

    if (existsItem) {
      return existsItem?.[0]?.name;
    }
    return null;
  };

  const convertData = (data: DataChart[]) => {
    let result: { x: number; y: number }[] = [];
    data?.map((item: DataChart, index: number) => {
      result?.push({
        x: index + 1,
        y: item?.value,
      });
    });
    if (result?.length === 0) return null;
    return result;
  };

  const initialFunc = () => {
    let temp: any = [[], []];
    if (dataLeft) {
      temp[0] = convertData(dataLeft);
    }
    if (dataRight) {
      temp[1] = convertData(dataRight);
    }
    setDataFinal(temp);
  };

  useEffect(() => {
    initialFunc();
  }, [dataLeft, dataRight]);

  useEffect(() => {
    if (dataLeft && dataRight) {
      const temp = getNameAndColor();
      if (temp?.every((item) => item === null)) {
        return;
      }
      setDataYAxis(getNameAndColor());
    }
  }, [dataFinal]);

  const findMaxY = (data: { x: number; y: number }[]) => {
    if (data?.length === 0) {
      return 0;
    }
    const yValues = data?.map((item: { x: number; y: number }) =>
      Math.abs(item.y)
    );
    if (yValues) {
      return Math?.max(...yValues);
    }
    return 1;
  };

  const tickPadding = [-5, 0, -5];

  const none = dataFinal?.every((item: DataFinalProps) => item?.length === 0);

  return (
    <div
      style={{
        width: width,
        background: '#fff',
        borderRadius: 10,
      }}
      className={className}
    >
      <div className="ml-3 mr-2 mt-2" style={{ fontWeight: '500' }}>
        {ChartName}
      </div>
      {none ? (
        <div
          style={{
            height: height - 20,
            width: width - 20,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: -20,
          }}
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        <VictoryChart
          style={{
            parent: { paddingLeft: 10, paddingRight: 10, marginTop: -10 },
          }}
          domainPadding={{ x: 15 }}
          theme={VictoryTheme.material}
          width={width}
          height={height}
          containerComponent={
            <VictoryVoronoiContainer
              responsive
              voronoiDimension="x"
              labels={({ datum }) => {
                return `${getName(
                  datum?.style?.data?.stroke ?? datum?.style?.data?.fill
                )}: ${formatPrice(datum.y?.toFixed(0))}`;
              }}
              labelComponent={
                <VictoryTooltip
                  constrainToVisibleArea
                  flyoutStyle={{
                    fill: '#fff',
                    stroke: 'rgba(0,0,0,0.4)',
                  }}
                  centerOffset={{ y: -40 }}
                  dy={0}
                  pointerLength={40}
                  labelComponent={
                    <VictoryLabel backgroundPadding={[{ bottom: 3, top: 3 }]} />
                  }
                  flyoutPadding={() => ({
                    top: 5,
                    bottom: 20,
                    left: 7,
                    right: 7,
                  })}
                />
              }
            />
          }
        >
          <VictoryLegend
            x={0}
            y={10}
            centerTitle
            orientation="horizontal"
            gutter={15}
            style={{ labels: { fontSize: fontSize } }}
            data={dataYAxis}
          />
          <VictoryAxis
            tickFormat={dataAxisY}
            style={{
              tickLabels: { fontSize: fontSize, angle: angle },
            }}
          />
          {dataFinal?.map(
            (d: DataFinalProps, i: number) =>
              d?.length > 0 && (
                <VictoryAxis
                  dependentAxis
                  key={i}
                  orientation={i === 1 ? 'right' : 'left'}
                  style={{
                    ticks: { padding: tickPadding[i] },
                    tickLabels: { fontSize: fontSize, angle: angle },
                  }}
                  tickValues={
                    ChartName === 'Tỷ lệ chiết khấu và ARPU'
                      ? [-1, -0.75, -0.5, -0.25, 0, 0.25, 0.5, 0.75, 1]
                      : [0, 0.25, 0.5, 0.75, 1]
                  }
                  tickFormat={(t) =>
                    formatPriceChart((t * findMaxY(d))?.toFixed(0) as any)
                  }
                />
              )
          )}
          <VictoryGroup offset={8}>
            {dataFinal[0]?.length > 0 && (
              <VictoryBar
                style={{
                  labels: { fontSize: 14 },
                  data: { fill: '#1663AB' },
                }}
                cornerRadius={{ top: 2 }}
                data={dataFinal[0]}
                barWidth={15}
                y={(datum) => datum.y / findMaxY(dataFinal[0])}
              />
            )}
          </VictoryGroup>
          {dataFinal[1]?.length > 0 && (
            <VictoryLine
              // interpolation="natural"
              data={dataFinal[1]}
              labels={[]}
              style={{
                labels: { fontSize: 14 },
                data: {
                  stroke: '#F9AB40',
                  strokeWidth: 2,
                },
              }}
              y={(datum) => datum.y / findMaxY(dataFinal[1])}
            />
          )}
        </VictoryChart>
      )}
    </div>
  );
};

export default TwoTypeChart;
