import { Col, DatePicker, Select, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import AppContext from 'src/context/AppContext';
import { DEFAULT_FORMAT_DATE, ROLES_LIST_MANAGER } from 'src/utils/constants';

type Props = {
  onChangeStaff: (staff: string) => void;
  onChangeFromDate: (fromDate: string) => void;
  onChangeToDate: (toDate: string) => void;
};
export const Filter = ({
  onChangeStaff,
  onChangeFromDate,
  onChangeToDate,
}: Props) => {
  const { appState } = React.useContext(AppContext);

  const onChange = (value: string[]) => {
    let result = '';
    value?.map((item, index) => {
      index === 0 ? (result = item) : (result += `,${item}`);
    });
    onChangeStaff(result);
  };

  return (
    <Space className="ml-[15px] mr-[15px] pb-[10px] flex items-center w-full">
      {appState?.profile?.roles[0] === ROLES_LIST_MANAGER[0]?.id && (
        <Col className="w-[300px] mb-0 rounded-[5px]">
          <Select
            mode="multiple"
            filterOption={(input, option) =>
              (option as { value: number; label: string }).label
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Chọn sale"
            allowClear
            showSearch
            options={appState?.listStaff?.[0]?.saleManList?.map(
              (item: { userName: string; fullName: string }) => ({
                value: item?.userName,
                label: item?.fullName,
              })
            )}
            maxTagCount="responsive"
            onChange={onChange}
            className="CascaderReport w-full border-[1px] border-solid border-[#d9d9d9]"
          />
        </Col>
      )}
      <DatePicker
        showTime
        placeholder="Chọn ngày bắt đầu"
        onChange={(value) => {
          onChangeFromDate(
            value ? moment(value).format(DEFAULT_FORMAT_DATE) : ''
          );
        }}
      />
      <DatePicker
        showTime
        placeholder="Chọn ngày kết thúc"
        onChange={(value) => {
          onChangeToDate(
            value ? moment(value).format(DEFAULT_FORMAT_DATE) : ''
          );
        }}
      />
    </Space>
  );
};
