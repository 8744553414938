import { Empty, Table } from 'antd';
import { useEffect } from 'react';
import { useCommissionRevenue } from 'src/hooks/dashboard/useDashboard';
import { convertDataChart } from 'src/utils';
import { REVENUE_LABEL } from 'src/utils/constants';
import { useWindowSize } from 'src/utils/customHooks';
import { formatPrice } from 'src/utils/formatter';
import { isNullOrEmpty } from 'src/utils/stringUtils';
import HozBarWithDetail from '../Charts/HozBarWithDetail';
import ThreeTypeChartSpecial from '../Charts/ThreeTypeChartSpecial';
import TwoTypeChart from '../Charts/TwoTypeChart';
import RevenueSkeleton from '../Skeleton/RevenueSkeleton';

const columns: any = [
  {
    title: 'Top',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 50,
    render: (_: any, __: any, indx: number) => {
      return <div style={{ marginTop: 8, marginBottom: 8 }}>{indx + 1}</div>;
    },
  },
  {
    title: 'Tên sale',
    dataIndex: 'staff',
    align: 'center',
    key: 'staff',
    width: '50%',
  },
  {
    title: 'Thời gian gọi gần nhất',
    dataIndex: 'time',
    align: 'center',
    key: 'time',
  },
];

const columns2: any = [
  {
    title: 'Top',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 50,
    render: (_: any, __: any, indx: number) => {
      return <div style={{ marginTop: 8, marginBottom: 8 }}>{indx + 1}</div>;
    },
  },
  {
    title: 'Tên sale',
    dataIndex: 'staff',
    align: 'center',
    key: 'staff',
    width: '50%',
  },
  {
    title: 'Đăng ký gần nhất',
    dataIndex: 'time',
    align: 'center',
    key: 'time',
  },
];

function CommissionRevenue({ members = {}, date }: any) {
  const { state, onChangeDate, onChangeStaff } = useCommissionRevenue();
  const windowSize = useWindowSize();

  const getYAxis = (Source: any) => {
    if (Source) {
      let result: any = [];
      Source?.map((item: any) => {
        result?.push(`${item?.hour}`);
      });
      return result;
    }
  };

  const chartWidth = (windowSize.width - 30) / 3;
  const chartHeight = (((windowSize.width - 30) / 3) * 4) / 6;

  useEffect(() => {
    onChangeDate(date);
  }, [date]);

  useEffect(() => {
    onChangeStaff(members);
  }, [members]);

  // const getYAxisLineChart = (Source: any) => {
  //   if (Source) {
  //     let result: any = [];
  //     Source?.map((item: any) => {
  //       result?.push(item?.hour);
  //     });
  //     return result;
  //   }
  // };

  const convertData = (index: number) => {
    switch (index) {
      case 0:
        return state?.generalData?.totalOrder
          ? formatPrice(state?.generalData?.totalOrder)
          : '0';
      case 1:
        return state?.generalData?.totalRevenue
          ? formatPrice(state?.generalData?.totalRevenue)
          : '0';
      case 2:
        return state?.generalData?.totalNetShareValue
          ? formatPrice(state?.generalData?.totalNetShareValue)
          : '0';
      case 3:
        return state?.generalData?.discountPercentage
          ? state?.generalData?.discountPercentage?.toFixed(2) + '%'
          : '0%';
      case 4:
        return state?.generalData?.arpu
          ? formatPrice(
              state?.generalData?.arpu < 0 ? 0 : state?.generalData?.arpu
            )
          : '0';
      case 5:
        return state?.contactData?.contactProcessRate
          ? state?.contactData?.contactProcessRate + '%'
          : '0%';
      case 6:
        return state?.contactData?.hotCloseRate
          ? state?.contactData?.hotCloseRate + '%'
          : '0%';
      default:
        return null;
    }
  };

  const convertHozChartData = (data: any, key: string) => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const totalCallArray = Object.entries(data).map(([id, item]: any) => {
        return {
          name: item?.object,
          value: item[key],
        };
      });

      if (totalCallArray?.every((item) => item?.value === 0)) {
        return null;
      }
      return totalCallArray;
    }
  };

  if (state?.loading) {
    return <RevenueSkeleton />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      className="pt-[50px]"
    >
      <div
        className="freezeTag bg-[#f2f2f2] fixed pt-[5px] pb-[10px] flex justify-center pr-[15px] pl-[15px]"
        style={{ zIndex: 2, marginTop: -2 }}
      >
        <div className="flex revenueOverview">
          {REVENUE_LABEL?.map((item, index) => {
            const data = convertData(index);
            return (
              <>
                <div key={item?.id} className="generalNumber">
                  <div className="revenuewOverviewLabel">{item?.label}</div>
                  <div className="revenueOverviewValue">{data}</div>
                </div>
                {index !== REVENUE_LABEL?.length - 1 && (
                  <div style={{ width: 1, background: 'rgba(0,0,0,0.1)' }} />
                )}
              </>
            );
          })}
        </div>
      </div>
      <div
        className="flex w-[100vw] pt-[72px] pl-[15px] pr-[15px] mb-[10px]"
        style={{
          justifyContent: 'space-between',
          // maxWidth: 1820,
          // width: 1820,
        }}
      >
        <ThreeTypeChartSpecial
          maxLeft={120000000}
          ChartName={'Doanh thu, Hoa hồng thực nhận và số đơn'}
          width={chartWidth}
          height={chartHeight}
          className="ThreeTypeChart"
          dataLeft1={convertDataChart(
            state?.chartData,
            'Doanh thu',
            'totalRevenue'
          )}
          dataLeft2={convertDataChart(
            state?.chartData,
            'Hoa hồng thực nhận',
            'totalNetShareValue'
          )}
          dataRight={convertDataChart(state?.chartData, 'Số đơn', 'totalOrder')}
          // dataAxisY={getYAxis(state?.chartData)}
          fontSize={10}
          angle={-30}
        />
        <TwoTypeChart
          ChartName={'Tỷ lệ chiết khấu và ARPU'}
          width={chartWidth}
          height={chartHeight}
          className="ThreeTypeChart mr-[10px] ml-[10px]"
          dataLeft={
            state?.filter?.staff?.length > 0
              ? convertDataChart(state?.arpuAndArpeData, 'ARPU', 'arpu')
              : convertDataChart(state?.chartData, 'ARPU', 'arpu')
          }
          dataRight={convertDataChart(
            state?.chartData,
            'Tỷ lệ chiết khấu',
            'discountPercentage'
          )}
          dataAxisY={getYAxis(state?.chartData)}
          angle={0}
        />

        <ThreeTypeChartSpecial
          maxLeft={3200}
          maxRight={16}
          ChartName={'Tỷ lệ xử lí contact và Tỷ lệ chốt nóng'}
          width={chartWidth}
          height={chartHeight}
          className="ThreeTypeChart"
          angle={0}
          dataLeft1={convertDataChart(
            state?.handledContact,
            'Số lượng contact được cấp',
            'totalContact'
          )}
          dataLeft2={convertDataChart(
            state?.handledContact,
            'Số lượng contact đã xử lý',
            'totalProcessed'
          )}
          dataRight={convertDataChart(state?.hotCloseContact, 'Tỷ lệ chốt')}
          // dataAxisY={getYAxis(state?.chartData)}
          fontSize={10}
        />
      </div>
      <div
        className="flex w-[100vw] mb-[10px] pl-[15px] pr-[15px]"
        style={{
          justifyContent: 'space-between',
          // maxWidth: 1820,
          // width: 1820,
        }}
      >
        <HozBarWithDetail
          ChartName={'Top 10 sale (Hoa hồng thực nhận)'}
          className="ThreeTypeChart"
          width={chartWidth}
          height={chartHeight}
          data={convertHozChartData(
            state?.bestSale,
            'totalNetShareValue'
          )?.reverse()}
        />
        <div
          className=" tableChartName mr-[10px] ml-[10px] "
          style={{ height: chartHeight, width: chartWidth }}
        >
          <div className="mb-3" style={{ fontWeight: '500' }}>
            Thời gian gọi gần nhất
          </div>
          {isNullOrEmpty(state?.lastestAction?.latestCall) ? (
            <div
              style={{
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginTop: -20,
              }}
            >
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          ) : (
            <Table
              className={'custom-table antTableCustom'}
              rowKey="id"
              dataSource={state?.lastestAction?.latestCall}
              scroll={{ y: (((windowSize.width - 30) / 3) * 4) / 6 - 100 }}
              size="small"
              columns={columns}
              // cardBordered
              // columnsState={{
              //   persistenceKey: 'pro-table-singe-demos',
              //   persistenceType: 'localStorage',
              // }}
              // dateFormatter="string"
              // searchFormRender={() => null}
              // toolBarRender={false}
              pagination={false}
            />
          )}
        </div>
        <div
          className="tableChartName"
          style={{ height: chartHeight, width: chartWidth }}
        >
          <div className="mb-3" style={{ fontWeight: '500' }}>
            Đăng kí gần nhất
          </div>
          {isNullOrEmpty(state?.lastestAction?.latestRegister) ? (
            <div
              style={{
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginTop: -20,
              }}
            >
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          ) : (
            <Table
              className={'custom-table antTableCustom'}
              rowKey="id"
              scroll={{ y: (((windowSize.width - 30) / 3) * 4) / 6 - 100 }}
              dataSource={state?.lastestAction?.latestRegister}
              columns={columns2}
              pagination={false}
            />
          )}
        </div>

        {/* <Bar
          ChartName={'Doanh thu sau chiết khấu/sale'}
          width={600}
          height={400}
          data={
            state?.filter?.staff?.length > 0
              ? convertDataChart(state?.arpuAndArpeData, 'ARPE', 'arpe')
              : convertDataChart(state?.chartData, 'arpe', 'arpe')
          }
        /> */}

        {/* <Line
          // yAxisData={getYAxisLineChart(state?.chartData)}
          hasDetail
          width={600}
          height={400}
          chartName={'Tỷ lệ chốt'}
          data={convertDataHotCloseContactChart(state?.hotCloseContact)}
          fontSize={10}
          percent
        /> */}
        {/* <ThreeTypeChart
        ChartName={'Tỷ lệ xử lí contact'}
        width={600}
        height={400}
        className="ThreeTypeChart"
        angle={0}
        dataLeft1={convertDataChart(
          state?.handledContact,
          'Số lượng contact được cấp',
          'totalContact'
        )}
        dataLeft2={convertDataChart(
          state?.handledContact,
          'Số lượng contact đã xử lý',
          'totalProcessed'
        )}
        dataRight={convertDataChart(
          state?.handledContact,
          'Tỷ lệ xử lý contact',
          'contactProcessRate'
        )}
        // dataAxisY={getYAxis(state?.chartData)}
        fontSize={10}
      /> */}
      </div>
    </div>
  );
}

export default CommissionRevenue;
