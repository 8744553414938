import { ConfigProvider } from 'antd';
import MyRoutes from './Routes';
import moment from 'moment';
import viVN from 'antd/es/locale/vi_VN';
import * as Sentry from '@sentry/react';
import LocalStoreInstance from './utils/localStorage';
import { AppProvider } from './context/AppContext';
import { LOCAL_STORAGE_VALUE } from './utils/enum';
import './input.css';
import './App.css';

const locale = viVN;
moment.locale('vi', {
  week: {
    dow: 1, /// Date offset
  },
  months: [
    'Tháng 1',
    'Tháng 2',
    'Tháng 3',
    'Tháng 4',
    'Tháng 5',
    'Tháng 6',
    'Tháng 7',
    'Tháng 8',
    'Tháng 9',
    'Tháng 10',
    'Tháng 11',
    'Tháng 12',
  ],
  monthsShort: [
    'Th1',
    'Th2',
    'Th3',
    'Th4',
    'Th5',
    'Th6',
    'Th7',
    'Th8',
    'Th9',
    'Th10',
    'Th11',
    'Th12',
  ],
  weekdays: ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'],
  weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
  weekdaysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
  relativeTime: {
    future: 'trong %s',
    past: '%s trước',
    s: 'vài giây trước',
    ss: '%d giây',
    m: '1 phút',
    mm: '%d phút',
    h: '1 giờ',
    hh: '%d giờ',
    d: '1 ngày',
    dd: '%d ngày',
    w: '1 tuần',
    ww: '%d tuần',
    M: '1 tháng',
    MM: '%d tháng',
    y: '1 năm',
    yy: '%d năm',
  },
});

Sentry.init({
  dsn: 'https://ba149ae522384037873fd7601c913775@o4504972405374976.ingest.sentry.io/4504972406882304',
  integrations: [new Sentry.BrowserTracing()],
  debug: false,
  tracesSampleRate: 1.0,
});

Sentry.configureScope(function (scope) {
  scope.setUser({
    id: LocalStoreInstance.getInstance().read(
      LOCAL_STORAGE_VALUE.currentLoggedUser
    )?.id,
  });
});

Sentry.withScope(function (scope) {
  scope.setTag('my-tag', 'my value');
  scope.setLevel('warning');
});

function App() {
  return (
    <AppProvider>
      <ConfigProvider locale={locale}>
        <MyRoutes />
      </ConfigProvider>
    </AppProvider>
  );
}

export default Sentry.withProfiler(App);
