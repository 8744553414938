import { Empty } from 'antd';
import { formatPrice } from 'src/utils/formatter';
import { formatPriceChart } from 'src/utils/stringUtils';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

type Props = {
  width: number;
  height: number;
  fontSize?: number;
  data?: DataChart[] | null;
  ChartName: string;
  className: any;
};

const HozBarWithDetail = ({
  height,
  width,
  data,
  ChartName,
  className,
}: Props) => {
  const getYAxis = (data: DataChart[]) => {
    let result: string[] = [];
    data?.map((item: DataChart) => {
      result?.push(item?.name);
    });
    return result;
  };

  const convertData = (data: DataChart[]) => {
    let result: { x: number; y: number }[] = [];
    data?.map((item: DataChart, index: number) => {
      result?.push({
        x: index + 1,
        y: item?.value,
      });
    });
    return result;
  };

  const findMaxY = (data: { x: number; y: number }[]) => {
    if (data.length === 0) {
      return 0;
    }
    const yValues = data.map((item: { x: number; y: number }) => item.y);
    return Math.max(...yValues);
  };

  return (
    <div
      style={{
        width: width,
        background: '#fff',
        borderRadius: 10,
      }}
      className={className}
    >
      <div className="ml-3 mr-2 mt-2" style={{ fontWeight: '500' }}>
        {ChartName}
      </div>
      {!data ? (
        <div
          style={{
            height: height - 20,
            width: width - 20,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: -20,
          }}
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        <VictoryChart
          style={{
            parent: { paddingLeft: 10, paddingRight: 10, marginTop: -20 },
          }}
          theme={VictoryTheme.material}
          height={height - 20}
          width={width - 20}
          domainPadding={{ x: 30 }}
          groupComponent={<g transform="translate(20, 0)" />}
          containerComponent={
            <VictoryVoronoiContainer
              voronoiDimension="x"
              labels={({ datum }) => {
                return `Hoa hồng thực nhận: ${
                  formatPrice(datum.y.toFixed(0)) as any
                }`;
              }}
              labelComponent={
                <VictoryTooltip
                  constrainToVisibleArea
                  flyoutStyle={{
                    fill: '#fff',
                    stroke: 'rgba(0,0,0,0.2)',
                  }}
                  dy={10}
                  labelComponent={
                    <VictoryLabel
                      style={{
                        stroke: 'rgba(0,0,0,0.2)',
                        fontSize: 14,
                        fontWeight: '400',
                        fontFamily: 'default',
                      }}
                    />
                  }
                  centerOffset={{ y: -10 }}
                  pointerWidth={20}
                  flyoutPadding={() => ({
                    left: 40,
                    right: 40,
                  })}
                />
              }
            />
          }
        >
          <VictoryAxis
            tickFormat={getYAxis(data)}
            style={{
              ticks: { padding: [10, 0, 0][0] },
              tickLabels: { fontSize: 10 },
            }}
            orientation="left"
            groupComponent={<g transform="translate(20, 0)" />}
          />
          <VictoryAxis
            dependentAxis
            style={{
              tickLabels: { fontSize: 10 },
            }}
            tickValues={[0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]}
            tickFormat={(t) => formatPriceChart(t * 10000000)}
            groupComponent={<g transform="translate(10, 0)" />}
          />
          <VictoryBar
            horizontal
            data={convertData(data)}
            labels={({ datum }) => formatPriceChart(datum.y) as string}
            style={{
              labels: { fill: 'black', fontSize: 10, marginRight: 100 },
              data: { fill: '#1663AB' },
            }}
            cornerRadius={{ top: 2 }}
            barWidth={10}
            labelComponent={<VictoryLabel dx={10} />}
            y={(datum) => datum.y / 10000000}
            groupComponent={<g transform="translate(20, 0)" />}
          />
        </VictoryChart>
      )}
    </div>
  );
};

export default HozBarWithDetail;
