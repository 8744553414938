import { Button, Col, Modal, Row, Typography } from 'antd';
import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { formatted } from 'src/utils/stringUtils';

export type ConfirmModalRef = {
  open: (values?: any) => void;
  close: () => void;
};

type Props = {
  handleFinishTransfer: (values: any) => void;
};
const ConfirmTransaction = (
  { handleFinishTransfer }: Props,
  ref: Ref<ConfirmModalRef>
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [topupValues, setTopupValue] = useState<{
    msisdn: string;
    amountTransfer: string;
  }>({ msisdn: '', amountTransfer: '' });

  const onClose = () => {
    setOpen(false);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: (data) => {
          setOpen(true);
          setTopupValue(data);
        },
        close: onClose,
      };
    },
    []
  );

  const onConfirm = () => {
    handleFinishTransfer?.(topupValues);
  };

  return (
    <Modal
      open={open}
      width={420}
      onCancel={onClose}
      title={<h3 className="title-cs">Xác nhận chuyển khoản</h3>}
      footer={null}
      maskClosable={false}
    >
      <div>
        <Row>
          <Col xl={4} lg={4} md={4} sm={4} xs={4}></Col>
          <Col xl={8} lg={8} md={8} sm={8} xs={8}>
            <p style={{ fontSize: 16 }}>Số điện thoại:</p>
            <p style={{ fontSize: 16 }}>Số tiền nạp:</p>
          </Col>
          <Col xl={10} lg={10} md={10} sm={10} xs={10}>
            <Typography.Title level={5}>{topupValues?.msisdn}</Typography.Title>
            <Typography.Title level={5}>
              {formatted(topupValues?.amountTransfer)} VNĐ
            </Typography.Title>
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={2}></Col>
        </Row>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
        <Button type="primary" htmlType="submit" onClick={onConfirm}>
          Đồng ý
        </Button>
      </div>
    </Modal>
  );
};

export const ModalConfirmTransaction = forwardRef(ConfirmTransaction);
