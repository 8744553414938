import React from 'react';
import { hexToRgbA } from 'src/utils';

type Props = {
  code: string;
  color: string;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
};

const LevelSaleCode = (props: Props) => {
  const { code, color, style, textStyle } = props;
  const textLevelSale: React.CSSProperties = {
    color,
    background: hexToRgbA(color),
    width: 70,
    height: 22,
    textAlign: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    border: '1px solid',
    borderColor: color,
  };

  return (
    <div style={{ ...textLevelSale, ...style }}>
      <p
        style={{
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
          ...textStyle,
        }}
      >
        {code}
      </p>
    </div>
  );
};

export default LevelSaleCode;
