export const FirstTab = () => {
  return (
    <div
      style={{
        background: '#1677FF',
        height: '2vw',
      }}
    >
      <p
        style={{
          width: '100%',
          textAlign: 'center',
          lineHeight: '2vw',
          fontWeight: 'bold',
          fontSize: 15,
        }}
      >
        Ngày
      </p>
    </div>
  );
};
