import { Form, Input } from 'antd';
import React, { FC } from 'react';
import { padStart } from 'lodash';
import useCountDown from 'src/hooks/useCountDown';
import { SaveOutlined } from '@ant-design/icons';

type Props = {
  time: number;
  otp: string;
  onSaveOtp: () => void;
  onEndTime: () => void;
  onChangeOTP: (e: any) => void;
};

const InputOTP: FC<Props> = ({
  time,
  otp,
  onSaveOtp,
  onEndTime,
  onChangeOTP,
}) => {
  const { seconds, minutes } = useCountDown({
    time,
    onCallback: onEndTime,
    isSetTime: true,
  });

  return (
    <Form.Item
      className="form-label"
      rules={[
        {
          required: true,
          message: 'Vui lòng nhập mã OTP',
        },
      ]}
      name="otp"
    >
      <Input
        placeholder={`Nhập mã xác nhận (${
          time > 0 &&
          `${padStart(String(minutes), 2, '0')}:${
            seconds.toString().split('.')[0].length === 1
              ? '0' + seconds.toString().split('.')[0]
              : seconds.toString().split('.')[0]
          }`
        })`}
        className="input"
        value={otp}
        onChange={onChangeOTP}
        addonAfter={
          <div className={otp ? 'cursor-pointer' : ''} onClick={onSaveOtp}>
            <SaveOutlined style={{ color: otp ? '#52c41a' : '#a8a8a8' }} />
          </div>
        }
      />
    </Form.Item>
  );
};

export default InputOTP;
