import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { formatPrice } from 'src/utils/formatter';
import { formatPriceChart } from 'src/utils/stringUtils';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
// import './style.scss';

type Props = {
  className: string;
  width: number;
  ChartName?: string;
  dataLeft1?: DataChart[] | null;
  dataRight?: DataChart[] | null;
  dataLeft2?: DataChart[] | null;
  height: number;
  dataAxisY?: string[];
  empty?: boolean;
  fontSize?: number;
  angle?: number;
};

type YAxisProps = {
  name?: string;
  symbol?: { fill: string };
};

type DataFinalProps = { x: number; y: number }[];

const ThreeTypeChart = ({
  className,
  width,
  ChartName,
  dataLeft1,
  dataRight,
  dataLeft2,
  height,
  // dataAxisY,
  empty,
  fontSize = 10,
  angle = 0,
}: Props) => {
  const [dataFinal, setDataFinal] = useState<DataFinalProps[]>([[], [], []]);
  const [dataYAxis, setDataYAxis] = useState<YAxisProps[]>([]);

  const getNameAndColor = () => {
    let result: YAxisProps[] = [];
    result?.push(
      dataLeft1?.[0]?.name
        ? {
            name: dataLeft1?.[0]?.name,
            symbol: { fill: '#1663AB' },
          }
        : {}
    );
    result?.push(
      dataLeft2?.[0]?.name
        ? {
            name: dataLeft2?.[0]?.name ?? '',
            symbol: { fill: '#EB2233' },
          }
        : {}
    );
    result?.push(
      dataRight?.[0]?.name
        ? {
            name: dataRight?.[0]?.name ?? '',
            symbol: { fill: '#F9AB40' },
          }
        : {}
    );

    return result;
  };

  const getName = (color: string) => {
    const existsItem = getNameAndColor()?.filter((item) => {
      return item?.symbol?.fill === color;
    });

    if (existsItem) {
      return existsItem?.[0]?.name;
    }
    return null;
  };

  const convertData = (data: DataChart[]) => {
    let result: { x: number; y: number }[] = [];
    data?.map((item: DataChart, index: number) => {
      result?.push({
        x: index + 1,
        y: item?.value,
      });
    });
    if (result?.length === 0) return null;
    return result;
  };

  const initialFunc = () => {
    let temp: any = [[], [], []];
    if (dataLeft1) {
      temp[0] = convertData(dataLeft1);
    }
    if (dataLeft2) {
      temp[1] = convertData(dataLeft2);
    }
    if (dataRight) {
      temp[2] = convertData(dataRight);
    }
    setDataFinal(temp);
  };

  useEffect(() => {
    initialFunc();
  }, [dataLeft1, dataLeft2, dataRight]);

  useEffect(() => {
    if (dataLeft1 && dataLeft2 && dataRight) {
      const temp: YAxisProps[] = getNameAndColor();
      if (temp?.every((item) => item === null)) {
        return;
      }
      setDataYAxis(getNameAndColor());
    }
  }, [dataFinal]);

  const findMaxY = (data: { x: number; y: number }[]) => {
    if (data?.length === 0) {
      return 0;
    }
    const yValues = data?.map((item: { x: number; y: number }) => item.y);
    if (yValues) return Math?.max(...yValues);
    return 1;
  };

  const tickPadding = [-5, 0, -5];

  const none = dataFinal?.every((item: DataFinalProps) => item?.length === 0);

  const dataAxisY = [
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
  ];

  const dataAxisYSpecial = [
    '7-8',
    '8-9',
    '9-10',
    '10-11',
    '11-12',
    '12-13',
    '13-14',
    '14-15',
    '15-16',
    '16-17',
    '17-18',
    '18-19',
    '19-20',
    '20-21',
    '21-22',
  ];

  return (
    <div
      style={{
        width: width,
        background: '#fff',
        borderRadius: 10,
        maxWidth: width,
      }}
      className={className}
    >
      <div className="ml-3 mr-2 mt-2" style={{ fontWeight: '500' }}>
        {ChartName}
      </div>
      {none ? (
        <div
          style={{
            height: height - 20,
            width: width - 20,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: -20,
          }}
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        <VictoryChart
          style={{
            parent: { paddingLeft: 10, paddingRight: 10, marginTop: -10 },
          }}
          domainPadding={{ x: 20 }}
          theme={VictoryTheme.material}
          width={width}
          height={height}
          containerComponent={
            <VictoryVoronoiContainer
              voronoiDimension="x"
              labels={({ datum }) => {
                return `${getName(
                  datum?.style?.data?.stroke ?? datum?.style?.data?.fill
                )}: ${formatPrice(datum.y)}`;
              }}
              voronoiPadding={40}
              responsive
              labelComponent={
                <VictoryTooltip
                  constrainToVisibleArea
                  flyoutStyle={{
                    fill: '#fff',
                    stroke: 'rgba(0,0,0,0.4)',
                  }}
                  centerOffset={{ y: -40 }}
                  dy={0}
                  pointerLength={40}
                  labelComponent={
                    <VictoryLabel backgroundPadding={[{ bottom: 3, top: 3 }]} />
                  }
                  flyoutPadding={() => ({
                    top: 5,
                    bottom: 20,
                    left: 7,
                    right: 7,
                  })}
                />
              }
            />
          }
        >
          <VictoryLegend
            x={0}
            y={10}
            centerTitle
            orientation="horizontal"
            gutter={15}
            style={{ labels: { fontSize: fontSize } }}
            data={dataYAxis}
          />
          <VictoryAxis
            tickFormat={
              ChartName === 'Doanh thu, Hoa hồng thực nhận và số đơn'
                ? dataAxisYSpecial
                : dataAxisY
            }
            style={{
              tickLabels: { fontSize: fontSize, angle: angle },
            }}
          />
          {dataFinal?.map(
            (d: DataFinalProps, i: number) =>
              i !== 1 &&
              d?.length > 0 && (
                <VictoryAxis
                  dependentAxis
                  key={i}
                  orientation={i === 2 ? 'right' : 'left'}
                  style={{
                    ticks: { padding: tickPadding[i] },
                    tickLabels: {
                      fontSize: fontSize,
                      angle: i === 2 ? 0 : angle,
                    },
                  }}
                  tickValues={[0, 0.25, 0.5, 0.75, 1]}
                  tickFormat={(t) => formatPriceChart((t * findMaxY(d)) as any)}
                />
              )
          )}
          <VictoryGroup offset={14}>
            {dataFinal?.map(
              (d: DataFinalProps, i: number) =>
                i !== 2 && (
                  <VictoryBar
                    key={i}
                    style={{
                      labels: { fontSize: 14 },
                      data: { fill: i == 0 ? '#1663AB' : '#EB2233' },
                    }}
                    cornerRadius={{ top: 2 }}
                    data={d}
                    barWidth={8}
                    y={(datum) => datum.y / findMaxY(dataFinal[0])}
                  />
                )
            )}
          </VictoryGroup>
          {dataFinal[2]?.length > 0 && (
            <VictoryLine
              // interpolation="natural"
              data={dataFinal[2]}
              labels={[]}
              style={{
                labels: { fontSize: 14 },
                data: {
                  stroke: '#F9AB40',
                  strokeWidth: 2,
                },
              }}
              y={(datum) => {
                return datum.y / findMaxY(dataFinal[2]);
              }}
            />
          )}
        </VictoryChart>
      )}
    </div>
  );
};

export default ThreeTypeChart;
