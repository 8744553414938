import { Button } from 'antd';
import React from 'react';
import { State } from '.';
import './style.scss';

type Props = {
  UIstate: State;
  onRecallData: () => void;
};

const ContentRecallData: React.FC<Props> = (props) => {
  const { UIstate, onRecallData } = props;
  return (
    <div className="modal-move-data">
      <p className="text text-bold">Xác nhận thu hồi data</p>
      <p className="text text-small">
        (Chỉ thu hồi data có trạng thái L0)
      </p>
      <div style={{ textAlign: 'start', marginTop: 12, marginBottom: 12 }}>
        <p className="text">
          Số data yêu cầu thu hồi:{' '}
          <span className="text text-bold">
            {UIstate.selectedPhones.length} data
          </span>
        </p>
        <p className="text">
          Số data được thu hồi:{' '}
          <span className="text text-bold">
            {UIstate.transferPhones.length} data
          </span>
        </p>
      </div>
      <Button
        onClick={onRecallData}
        loading={UIstate.loadingRecall}
        type="primary"
      >
        Đồng ý
      </Button>
    </div>
  );
};

export default ContentRecallData;
