import { deleteParamsNotUsing, getQueryString } from 'src/utils/stringUtils';
import APIService from '../utils/apiServices';

class TalktimeServices extends APIService {
  async getData(params: { fromDate: string; staffs: string; toDate: string }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(
      `report/call/overview-by-leader-and-staff`,
      params
    );
    return await this.request('GET', url);
  }
}
const talkTimeServices = new TalktimeServices();
export default talkTimeServices;
