import { CheckOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { TYPE_CREATE, TYPE_EDIT } from "src/constants";
import Conditions from "./Conditions/Conditions";
import "./style.scss";
import SketchPickerCustom from "./SketchPickerCustom";

type Props = {
  type: string;
  sales: LevelSaleItem[];
  selectItem?: LevelSaleItem;
  isSaleParent: boolean;
  onHide: () => void;
  onSumit: (
    params: CreateLevelSaleParams,
    isCreate: boolean,
    id?: number
  ) => void;
};

const ModalCreateLeveSale: React.FC<Props> = ({
  type,
  sales,
  isSaleParent,
  selectItem,
  onHide,
  onSumit,
}) => {
  const [form] = Form.useForm();
  const formRef = React.useRef<FormInstance<CreateLevelSaleParams> | null>(
    null
  );

  const [tags, setTags] = useState<string[]>(
    type === TYPE_EDIT ? selectItem?.reasons ?? [] : []
  );

  useEffect(() => {
    formRef?.current?.setFieldsValue({
      code: selectItem?.code,
      definition: selectItem?.definition,
      parentLevel: selectItem?.parentLevel,
      displayColor: selectItem?.displayColor,
    });
    
  }, []);

  const handleChangeTags = (value: string[]) => {
    setTags(value);
  };

  const onFinish = (values: any) => {
    const data = { ...values, reasons: tags };
    if (type === TYPE_EDIT && selectItem?.code === data?.code) {
      delete data.code
    } 
    onSumit(data, type === TYPE_CREATE, selectItem?.id);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeColor = (color: string) => {
    formRef?.current?.setFieldsValue({
      displayColor: color,
    });
  };

  return (
    <Modal
      title={`${type === TYPE_EDIT ? "CHỈNH SỬA" : "THÊM MỚI"} LEVEL SALE`}
      open={true}
      centered
      onCancel={onHide}
      footer={null}
      width={"50%"}
      wrapClassName="edit-level-sale-modal"
    >
      <Form
        form={form}
        ref={formRef}
        name="edit-level-sale-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        colon={false}
        layout="vertical"
      >
        <Row gutter={[{ xl: 16, lg: 16, md: 16, sm: 16, xs: 16 }, {}]}>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="code"
              label="Level Sale"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập level sale",
                },
              ]}
            >
              <Input allowClear placeholder="Nhập level sale" maxLength={20}/>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="definition"
              label="Định nghĩa"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập định nghĩa",
                },
              ]}
            >
              <Input allowClear placeholder="Nhập định nghĩa" />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item name="parentLevel" label="Chọn vị trí cấp trên">
              <Select
                allowClear
                disabled={isSaleParent}
                placeholder="Chọn level cấp trên"
                optionFilterProp="value"
                menuItemSelectedIcon={
                  <CheckOutlined style={{ fontSize: 18 }} />
                }
              >
                {sales?.map((item: any) => {
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      {item.code}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Màu nền" name={"displayColor"}>
              <SketchPickerCustom
                color={selectItem?.displayColor ?? "#000"}
                onChangeColor={onChangeColor}
              />
            </Form.Item>
          </Col>

          <Col xl={24} lg={12} md={12} sm={24} xs={24}>
            <Form.Item name="reasons" label="Bộ lý do">
              <Conditions tags={tags} handleChangeTags={handleChangeTags} />
            </Form.Item>
          </Col>

          <div className="footer">
            <Space size={16}>
              {type === TYPE_EDIT && (
                <Form.Item>
                  <Button
                    ghost
                    style={{
                      color: "rgba(0, 0, 0, 0.5)",
                      border: "1px solid rgba(0, 0, 0, 0.5)",
                    }}
                    onClick={onHide}
                  >
                    Huỷ bỏ
                  </Button>
                </Form.Item>
              )}
              <Form.Item shouldUpdate>
                {() => (
                  <Button type="primary" onClick={form.submit}>
                    {type === TYPE_EDIT ? "Lưu" : "Thêm mới"}
                  </Button>
                )}
              </Form.Item>
            </Space>
          </div>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalCreateLeveSale;
