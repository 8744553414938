import APIService from 'src/utils/apiServices';
import { deleteParamsNotUsing, getQueryString } from 'src/utils/stringUtils';

class ProductServices extends APIService {
  async createPackage(params: ListPackage) {
    return await this.request('POST', `categories/phone-packages`, params);
  }
  async detailsPackage(id: number) {
    return await this.request(
      'GET',
      `categories/phone-packages/${id || ''}`,
      {}
    );
  }
  async updatePackage(params: ListPackage, id: number) {
    return await this.request(
      'PUT',
      `categories/phone-packages/${id || ''}`,
      params
    );
  }
  async listPackage(params: ParamsListPackage) {
    deleteParamsNotUsing(params);
    const url = getQueryString('categories/info-phone-packages', params);
    return await this.request('GET', url);
  }
}
export const productService = new ProductServices();
