import React from 'react';
import { DatePicker, Select, Space } from 'antd';
import SelectSaleMan from 'src/components/SelectSaleMan';
import { datePickerStyle, filterBoxStyle } from 'src/styles';
import { State } from '../..';
import moment from 'moment';
import { DATE_FORMAT } from 'src/utils/constants';


type Props = {
  state: State;
  allowClearRangePicker?: boolean;
  handleChangePackage: (value: string[]) => void;
  handleChangeAssignedDate: (value: any) => void;
  handleChangeStaff: (value: string) => void;
};

const Filters: React.FC<Props> = ({
  state,
  allowClearRangePicker = true,
  handleChangePackage,
  handleChangeAssignedDate,
  handleChangeStaff,
}) => {
  return (
    <Space wrap>
      <Select
        allowClear
        showSearch
        key="currentPackages"
        mode="multiple"
        placeholder="Gói đang sử dụng"
        style={filterBoxStyle}
        maxTagCount="responsive"
        onChange={handleChangePackage}
      >
        {state?.listPackage?.map((item: any) => (
          <Select.Option key={item.id} value={item.code}>
            {item.code}
          </Select.Option>
        ))}
      </Select>
      <SelectSaleMan
        style={filterBoxStyle}
        treeData={state.staff}
        value={state.selectedStaff}
        onChange={handleChangeStaff}
        placeholder={'Chọn saleman'}
      />
      <DatePicker.RangePicker
        allowClear={allowClearRangePicker}
        format={DATE_FORMAT}
        style={{...datePickerStyle, width: '17vw'}}
        defaultValue={[
          moment().startOf('month').startOf('day'),
          moment().endOf('day'),
        ]}
        ranges={{
          'Hôm nay': [moment().startOf('day'), moment().endOf('day')],
          'Tuần này': [moment().startOf('week'), moment().endOf('week')],
          'Tháng này': [moment().startOf('month'), moment().endOf('month')],
        }}
        placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
        onChange={handleChangeAssignedDate}
      />
    </Space>
  );
};

export default Filters;
