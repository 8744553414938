import React, { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import AppContext from 'src/context/AppContext';
import Stomp from 'stompjs';
import { notification } from 'antd';

type Message = {
  msisdn: string;
  actionStaff: string;
  isSuccess: boolean;
  message: string;
};
export const useNotificationWebSocket = () => {
  const { appState }: ContextType = React.useContext(AppContext);

  const [message, setMessage] = useState<Message>();
  const [wsFrame, setWsFrame] = useState<Stomp.Frame>();

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    const userId = appState.profile?.id; // Thay đổi userId tùy theo người dùng cụ thể
    const socket = new SockJS(socketUrl);
    const stompClient = Stomp.over(socket);

    stompClient.connect({}, function (frame: Stomp.Frame) {
      setWsFrame(frame);
      if (userId) {
        stompClient.subscribe(
          `/user/${userId}/mobinext`,
          function (messageWs: Stomp.Message) {
            const messageContent = JSON.parse(messageWs.body);
            setMessage(messageContent);
            if (messageContent.isSuccess) {
              notification.success({
                message: 'Tra cứu thành công',
                description: messageContent.message,
              });
            } else {
              notification.error({
                message: 'Tra cứu thất bại',
                description: messageContent.message,
              });
            }
          }
        );
      }
    });
    if (!appState.profile?.id) {
      socket.close();
      stompClient.disconnect();
    }

    return () => {
      wsFrame?.command == 'CONNECTED' &&
        appState.profile?.id &&
        stompClient.disconnect(); // Ngắt kết nối khi component bị unmount
      socket.close();
    };
  }, [appState.profile?.id]);

  return {
    message,
  };
};
