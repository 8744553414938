import {
  DownloadOutlined,
  PauseOutlined,
  PlayCircleFilled,
} from '@ant-design/icons';
import { Space } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';

type Props = {
  url: string | null;
  duration: string;
  urlDownload: string | null;
  isPlaying: boolean;
  onPlayClick: (recordId: any) => void;
  record: any;
  handlePauseClick: () => void;
};

const AudioPlayer: FC<Props> = ({
  url,
  duration,
  urlDownload,
  isPlaying,
  onPlayClick,
  record,
  handlePauseClick,
}) => {
  const audioRef = useRef<any>(null);
  const [currentTime, setCurrentTime] = useState(0);

  const handlePlayClick = (id) => {
    onPlayClick(id);
  };

  const handleSeekChange = (event: any) => {
    setCurrentTime(event.target.value);
    audioRef.current.currentTime = event.target.value;
  };

  const convertToMmSs = (seconds: number) => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = Math.floor(seconds % 60);
    return remainingSeconds > 0
      ? `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
      : '00:00';
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = urlDownload as any;
    link.setAttribute('download', 'audio.mp3');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <Space
      align="center"
      style={{
        background: '#E2E2E2',
        borderRadius: '16px',
        paddingRight: 8,
        alignItems: 'center',
      }}
    >
      <audio
        ref={audioRef}
        src={url as any}
        onTimeUpdate={() => setCurrentTime(audioRef.current?.currentTime)}
      />
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        {isPlaying ? (
          <PauseOutlined
            onClick={handlePauseClick}
            style={{ color: '#277CBE', fontSize: 14 }}
          />
        ) : (
          <PlayCircleFilled
            onClick={() => handlePlayClick(record.id)}
            style={{ color: '#277CBE', fontSize: 14 }}
          />
        )}
      </div>
      <div>
        {convertToMmSs(audioRef.current?.currentTime)}/{duration.slice(3, 8)}
      </div>
      <input
        style={{ width: 60, marginTop: 2 }}
        type="range"
        id="seek-slider"
        min={0}
        max={audioRef.current && audioRef.current.duration}
        step={0.01}
        value={currentTime}
        onChange={handleSeekChange}
      />
      <DownloadOutlined onClick={handleDownload} style={{ fontSize: 16 }} />
    </Space>
  );
};

export default AudioPlayer;
