export const textCenterLeft: React.CSSProperties = {
  textAlign: 'left',
};

export const marginTop12: React.CSSProperties = {
  marginTop: 12,
};

export const paginationStyles: React.CSSProperties = {
  paddingTop: 12,
  paddingRight: 12,
  height: 40,
  justifyContent: 'flex-end',
  display: 'flex',
};

export const itemStyle: React.CSSProperties = { width: '11.97vw' };

export const filterStyles: React.CSSProperties = {
  margin: 8,
  paddingLeft: 8,
  position: 'relative',
  width: '100%',
};

export const fontSizeMedium: React.CSSProperties = { fontSize: '0.73vw' };

export const contentBox: React.CSSProperties = {
  display: 'flex',
  marginBottom: '1vh',
  alignItems: 'center',
};

export const contentBoxDivided: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

export const boxFilterStyle: React.CSSProperties = {
  border: '1px solid rgba(0, 0, 0, 0.3)',
};

export const inputStyle: React.CSSProperties = {
  width: '12vw',
  marginLeft: '0.32rem',
  ...boxFilterStyle,
  borderRadius: 0,
};

export const inputStyleDivided: React.CSSProperties = {
  width: '18vw',
  marginLeft: '0.32rem',
  marginBottom: '5px',
  borderRadius: 0,
};

export const filterBoxStyle: React.CSSProperties = {
  width: '12vw',
  marginLeft: '0.32rem',
};

export const datePickerStyle: React.CSSProperties = {
  width: '14vw',
  marginLeft: 6,
  ...boxFilterStyle,
  borderRadius: 0,
};

export const txtTitle: React.CSSProperties = {
  width: '7vw',
  padding: 0,
  margin: 0,
  fontSize: '0.75vw',
};

export const txtTitleDivided: React.CSSProperties = {
  width: 'auto',
  padding: 0,
  margin: 0,
  fontSize: '0.75vw',
};
