import { useEffect } from 'react';
import { useSalesEffectiveness } from 'src/hooks/dashboard/useDashboard';
import { convertDataChart } from 'src/utils';
import { SALE_INDEX } from 'src/utils/constants';
import ThreeTypeChart from '../Charts/ThreeTypeChart';
import TwoTypeChart from '../Charts/TwoTypeChart';
import SalesSkeleton from '../Skeleton/SaleSkeleton';
import './style.scss';
import { useWindowSize } from 'src/utils/customHooks';
import { formatPrice } from 'src/utils/formatter';

function SalesEffectiveness({ members = {}, date = '' }: any) {
  const { state, onChangeStaff, onChangeDate } = useSalesEffectiveness();
  const windowSize = useWindowSize();

  useEffect(() => {
    onChangeStaff(members?.staff);
  }, [members]);

  useEffect(() => {
    onChangeDate(date);
  }, [date]);

  const getYAxis = (Source: any) => {
    if (Source) {
      const hourArray = Object.values(Source)?.map((item: any) => item?.hour);
      return hourArray;
    }
  };

  const returnChartWidth = () => {
    if (windowSize.width <= 1400) {
      return 600;
    } else if (windowSize.width > 1800) {
      return 800;
    } else {
      return (windowSize.width - 30) / 2;
    }
  };

  const returnChartHeight = () => {
    if (windowSize.width <= 1400) {
      return 400;
    } else if (windowSize.width > 1800) {
      return (800 * 4) / 6;
    } else {
      return (((windowSize.width - 30) / 2) * 4) / 6;
    }
  };

  const convertData = (index: number) => {
    switch (index) {
      case 0:
        return state?.generalData?.talkTimeAvg ?? '0';
      case 1:
        return state?.generalData?.contactRate?.toFixed(2) ?? '0';
      case 2:
        return state?.generalData?.successRate
          ? state?.generalData?.successRate + '%'
          : '0%';
      case 3:
        return formatPrice(state?.generalData?.totalCall) ?? '0';
      default:
        return null;
    }
  };

  if (state?.loading) {
    return <SalesSkeleton />;
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        className="flex fixed bg-[#f2f2f2] mt-[50px] justify-center pt-[5px] pb-[10px] pr-[15px] pl-[15px] freezeTag"
        style={{ left: 0, top: -2, zIndex: 2, right: 0 }}
      >
        <div className="flex max-w-full bg-[white] justify-evenly p-[5px] rounded-[10px] w-full">
          {SALE_INDEX?.map((item, index) => {
            const data = convertData(index);
            return (
              <>
                <div key={item?.id} className="generalNumber">
                  <div className="saleOverviewLabel">{item?.label}</div>
                  <div className="saleOverviewValue">{data}</div>
                </div>
                {index !== SALE_INDEX?.length - 1 && (
                  <div style={{ width: 1, background: 'rgba(0,0,0,0.1)' }} />
                )}
              </>
            );
          })}
        </div>
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        className="pb-10 pt-[123px] w-full"
      >
        <div
          className="flex w-full mb-[10px]"
          style={{ justifyContent: 'center' }}
        >
          <TwoTypeChart
            // empty={isNullOrEmpty(state?.accumulation)}
            ChartName={'Số cuộc gọi, số sale gọi'}
            width={returnChartWidth()}
            height={returnChartHeight()}
            className="ThreeTypeChart mr-[10px]"
            dataLeft={convertDataChart(
              state?.accumulation,
              'Số phút gọi',
              'totalTime'
            )}
            dataRight={convertDataChart(
              state?.accumulation,
              'Số sale gọi',
              'numberOfStaff'
            )}
            dataAxisY={getYAxis(state?.callConnect)}
          />
          <ThreeTypeChart
            // empty={isNullOrEmpty(state?.callConnect)}
            ChartName={
              'Cuộc gọi đi, Cuộc gọi thành công và Tỷ lệ kết nối thành công'
            }
            width={returnChartWidth()}
            height={returnChartHeight()}
            className="ThreeTypeChart"
            dataLeft1={convertDataChart(
              state?.callConnect,
              'Cuộc gọi đi',
              'totalCall'
            )}
            dataLeft2={convertDataChart(
              state?.callConnect,
              'Cuộc gọi thành công',
              'totalSuccess'
            )}
            dataRight={convertDataChart(
              state?.callConnect,
              'Tỷ lệ kết nối thành công',
              'successRate'
            )}
            // dataAxisY={getYAxis(state?.callConnect)}
          />
        </div>
      </div>
    </div>
  );
}

export default SalesEffectiveness;
