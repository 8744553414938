import './style.scss';

type Props = {
  suggestPacks: any;
};

// eslint-disable-next-line react/prop-types
const SuggestPacks: React.FC<Props> = ({ suggestPacks }) => {
  return (
    <>
      <div className="content-basic-info">
        <div className="box-title">
          <div className="text-title">Danh sách gói cước có thể đăng ký</div>
        </div>
        <strong>{suggestPacks}</strong>
      </div>
    </>
  );
};

export default SuggestPacks;
