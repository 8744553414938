import { LeftCircleOutlined } from '@ant-design/icons';
import { Cascader, Col, DatePicker, Row, Tabs, TabsProps } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from 'src/context/AppContext';
import { DATE_FORMAT, DEFAULT_FORMAT_DATE } from 'src/utils/constants';
import { isNullOrEmpty } from 'src/utils/stringUtils';
import CommissionRevenue from '../Tabs/CommissionRevenue';
import SalesEffectiveness from '../Tabs/SalesEffectiveness';
import './style.scss';

function DashboardController() {
  const navigate = useNavigate();
  const [team, setTeam] = useState<any>('');
  const [mem, setMem] = useState<any>('');
  const [position] = useState<any>(['left', 'right']);
  const [chossenMem, setChossenMem] = useState<any>({
    teamLeader: '',
    staff: '',
  });
  const [date, setDate] = useState<any>([]);
  const { appState } = React.useContext(AppContext);

  useEffect(() => {
    if (team?.length > 0) {
      let result = '';
      let temp = getMemberFollowingTeam();
      if (temp) {
        temp?.map((item: any) => {
          if (result?.length === 0) {
            result += item?.value;
          } else {
            result += `,${item?.value}`;
          }
        });
      }
      setChossenMem({
        teamLeader: team,
        staff: result,
      });
    }
  }, [team]);

  const onChangeDate = (v: Date) => {
    setDate(moment(v).startOf('day').format(DEFAULT_FORMAT_DATE));
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Doanh thu hoa hồng',
      children: <CommissionRevenue members={chossenMem} date={date} />,
    },
    {
      key: '2',
      label: 'Hiệu quả của sale',
      children: <SalesEffectiveness members={chossenMem} date={date} />,
    },
  ];

  const getAllTeam = () => {
    let result: any = [];
    appState?.listStaff?.map((item) =>
      result?.push({
        label: item?.userName,
        value: item?.userName,
      })
    );
    return result;
  };

  const getMemberFollowingTeam = () => {
    let result: any = [];
    const index = appState?.listStaff?.findIndex(
      (item) => item?.userName === team
    );
    if (index !== -1) {
      appState?.listStaff?.[index]?.saleManList?.map((item2: any) => {
        result?.push({
          label: item2?.userName,
          value: item2?.userName,
        });
      });
    }
    return result;
  };

  const getAllMember = () => {
    let result: any = [];
    if (team?.length > 0) {
      result = getMemberFollowingTeam();
    } else {
      appState?.listStaff?.map((item) => {
        item?.saleManList?.map((item2: any) => {
          result?.push({
            label: item2?.userName,
            value: item2?.userName,
          });
        });
      });
    }

    return result;
  };

  const OperationsSlot: Record<any, React.ReactNode> = {
    left: (
      <div className="flex">
        <LeftCircleOutlined
          style={{ fontSize: 20 }}
          className="mr-5"
          onClick={() => navigate('/')}
        />
        <div className="mr-10" style={{ fontSize: 22, fontWeight: '700' }}>
          Dashboard
        </div>
      </div>
    ),
    right: (
      <Row
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Col className="w-60 mb-0 mr-3" style={{ borderRadius: 5 }}>
          <Cascader
            className="CascaderReport"
            style={{ width: '100%' }}
            placeholder="Chọn team"
            allowClear
            showSearch
            options={getAllTeam()}
            bordered
            value={team}
            onChange={(v) => {
              if (isNullOrEmpty(v)) {
                setMem('');
                setChossenMem({
                  teamLeader: '',
                  staff: '',
                });
              } else {
                if (mem?.length > 0) {
                  setMem('');
                }
              }
              setTeam(v?.[0]);
            }}
            maxTagCount="responsive"
          />
        </Col>
        <Col className="w-60 mb-0 mr-3" style={{ borderRadius: 5 }}>
          <Cascader
            className="CascaderReport"
            style={{ width: '100%' }}
            placeholder="Chọn sale"
            allowClear
            showSearch
            options={getAllMember()}
            value={mem}
            onChange={(value) => {
              // if (isNullOrEmpty(value)) {
              //   setTeam('');
              // }

              setMem(value?.[0]);
              setChossenMem({
                teamLeader: '',
                staff: value?.[0],
              });
            }}
            maxTagCount="responsive"
          />
        </Col>
        <Col className="w-50 mb-0 mr-3" style={{ borderRadius: 5 }}>
          <DatePicker
            style={{ borderColor: 'rgba(0,0,0,0.0)', borderRadius: 5 }}
            className="w-full"
            bordered={true}
            format={DATE_FORMAT}
            defaultValue={moment()}
            placeholder={'Từ ngày'}
            onChange={(v: any) => {
              if (!v) {
                onChangeDate(moment() as any);
              } else {
                onChangeDate(v);
              }
            }}
          />
        </Col>
      </Row>
    ),
  };

  const slot = useMemo(() => {
    return position.reduce(
      (acc: any, direction: any) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {}
    );
  }, [position, getAllTeam()]);

  return (
    <div className="flex justify-center">
      <Tabs
        tabBarExtraContent={slot}
        items={items}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
        className="customTabDashboard"
        size="small"
        tabBarStyle={{
          position: 'absolute',
          zIndex: 2,
          background: '#f2f2f2',
          width: '99%',
          paddingLeft: '10px',
          paddingTop: '10px',
          minWidth: 1150,
        }}
      />
    </div>
  );
}

export default DashboardController;
