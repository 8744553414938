import { Button, Form, Input } from 'antd';
import React from 'react';
import { validateamountTopup } from 'src/utils/helper';
import { formatted } from 'src/utils/stringUtils';
import { ModalConfirmTransaction } from './ModalConfirm';

type Props = {
  phoneNumber?: string;
  openModalConfirmTransaction: (values: any) => void;
  refConfirmTransaction: any;
  handleFinishTransfer: (values: any) => void;
};

export const CustomerTransfer = ({
  phoneNumber,
  openModalConfirmTransaction,
  refConfirmTransaction,
  handleFinishTransfer,
}: Props) => {
  const [form] = Form.useForm();
  const [isDisable, setIsDisable] = React.useState<boolean>(true);

  const onFinish = (values: any) => {
    let params = {
      msisdn: phoneNumber,
      amountTransfer: values.amountTransfer?.replace(/\D/g, '') || 0,
    };
    openModalConfirmTransaction?.(params);
    form.resetFields();
  };

  return (
    <div>
      <p className="text-center mb-[24px]">
        Khách hàng: <strong className="text-[#1777FF]">{phoneNumber}</strong>
      </p>
      <div className="w-[300px] mt-3 mb-2">
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name={'amountTransfer'}
            rules={[{ validator: validateamountTopup }]}
          >
            <Input
              placeholder="Nhập số tiền khách chuyển khoản..."
              allowClear
              onChange={(e: { target: { value: string } }) => {
                const newValue = e.target.value;
                if (newValue.trim() !== '') {
                  setIsDisable(false);
                } else {
                  setIsDisable(true);
                }
                form.setFieldsValue({
                  amountTransfer: formatted(e.target?.value),
                });
              }}
            />
          </Form.Item>
          <div className="flex justify-center">
            <Button type="primary" htmlType="submit" disabled={isDisable}>
              Xác nhận
            </Button>
          </div>
        </Form>
      </div>
      <ModalConfirmTransaction
        ref={refConfirmTransaction}
        handleFinishTransfer={handleFinishTransfer}
      />
    </div>
  );
};
