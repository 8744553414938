import { Helmet } from 'react-helmet';
import DashboardController from './DashboardController';
import './style.scss';

function Dashboard() {
  // const { appState, dispatch } = React.useContext(AppContext);

  return (
    <div
      className="overflow-y-scroll h-full"
      style={{
        background: '#f2f2f2',
        overflowX: 'hidden',
      }}
    >
      <Helmet>
        <title>Dashboard</title>
        {/* <meta name="description" content={projectName} /> */}
      </Helmet>

      <DashboardController />
    </div>
  );
}

export default Dashboard;
