import { APP_CONTEXT_ACTIONS } from './appAction';
import { Action, initialAppState } from './AppContext';

// Create a reducer function to handle state changes
export const reducer = (prevState: AppStorage, action: Action) => {
  switch (action.type) {
    // Add your state update cases here
    case APP_CONTEXT_ACTIONS.CLEAR_APP_DATA:
      return initialAppState;
    case APP_CONTEXT_ACTIONS.UPDATE_PROFILE:
      return {
        ...prevState,
        profile: action.payload,
      };
    case APP_CONTEXT_ACTIONS.LOAD_STATE:
      return {
        ...action.payload,
        loadingState: false,
      };
    case APP_CONTEXT_ACTIONS.UPDATE_PACKAGES:
      return {
        ...prevState,
        phonePackages: action.payload?.phonePackages,
      };

    case APP_CONTEXT_ACTIONS.SET_TOTAL_DATA_CARING:
      return {
        ...prevState,
        dataDividedRemain: action.payload?.dataDividedRemain ?? 0,
        customerCareRemain: action.payload?.customerCareRemain ?? 0,
        dataNewRemain: action.payload?.dataNewRemain ?? 0,
      };
    case APP_CONTEXT_ACTIONS.SET_CURRENT_COLLAPSE:
      return {
        ...prevState,
        currentCollapse: action.payload,
      };
    case APP_CONTEXT_ACTIONS.SET_LIST_STAFF:
      return {
        ...prevState,
        listStaff: action.payload,
      };
    default:
      return prevState;
  }
};
