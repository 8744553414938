/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, InputNumber, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { formatter, parser } from 'src/utils/formatter';
import ModalTopUp from '../ModalTopUp';
import './style.scss';
import { AMOUNT_VALUES } from 'src/utils/constants';

type Props = {
  phone?: string;
  dataId?: number;
  openTopUp?: boolean;
  handleHideTopUp: () => void;
  callBackUpdate?: () => void;
};

const TopUpContent: React.FC<Props> = ({
  phone,
  dataId,
  openTopUp,
  handleHideTopUp,
  callBackUpdate,
}) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [topUpValues, setTopUpValues] = React.useState<any>();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = () => {
    setIsModalOpen(true);
    handleHideTopUp();
  };

  useEffect(() => {
    if (!openTopUp) {
      form.resetFields();
    }
  }, [openTopUp]);

  return (
    <div className="top-up-form">
      <p className="text-center mb-[24px]">
        Nạp thẻ cho SĐT: <strong className="text-[#1777FF]">{phone}</strong>
      </p>
      <Form
        form={form}
        name="top-up-form"
        onFinish={onFinish}
        autoComplete="off"
        colon={false}
        requiredMark={false}
        onValuesChange={(changedValues: any, allValues: any) => {
          setTopUpValues(allValues);
        }}
      >
        <Form.Item
          name="amount"
          className="w-[300px]"
          rules={[{ required: true, message: 'Vui lòng chọn mệnh giá tiền' }]}
        >
          <Select
            placeholder="Vui lòng chọn mệnh giá "
            style={{ width: '100%' }}
            options={AMOUNT_VALUES}
            allowClear
          />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '24px 0 12px',
          }}
        >
          <Button type="primary" htmlType="submit">
            Xác nhận
          </Button>
        </div>
      </Form>
      <ModalTopUp
        isModalOpen={isModalOpen}
        phone={phone}
        dataId={dataId}
        topUpValues={topUpValues}
        handleOk={handleOk}
        handleCancel={handleCancel}
        callBackUpdate={callBackUpdate}
      />
    </div>
  );
};

export { TopUpContent };
