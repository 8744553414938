/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useContext, useEffect, useState } from 'react';
import SelectOptions from 'src/components/SelectOptions';
import { Button, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import {
  DATA_REGISTER,
  TYPE_REGISTER_PHONE_PACKAGE,
} from 'src/utils/constants';
import PopupHeader from './PopupHeader';
import AppContext from 'src/context/AppContext';
import InputOTP from './InputOTP';

interface IRegisterPopupProps {
  profile?: DataDividedProfile;
  state: InfoState;
  onCreateRequest: (packagesCode: string, type: string) => any;
  updateOtp: (value: string) => void;
  onSaveOtp: () => void;
  hiddenModal: () => void;
  onRegisterPackage: () => any;
}

export default function RegisterPopup({
  profile,
  state,
  onCreateRequest,
  updateOtp,
  onSaveOtp,
  hiddenModal,
  onRegisterPackage,
}: IRegisterPopupProps) {
  const phoneNumber =
    profile?.subscriberInfoWithoutSearching?.phoneNumber ??
    profile?.subscriberInfoWithSearching?.phoneNumber;

  const [form] = useForm();

  const [selectedTypeRegister, setSelectedTypeRegister] = useState<string>(
    state.currentRequest?.channel ?? TYPE_REGISTER_PHONE_PACKAGE.GA
  );

  const [selectedChannel, setSelectedChannel] = useState<any>(undefined);
  const { phonePackages } = useContext(AppContext).appState;

  const handleOptionChange = (id: string) => {
    if (id !== selectedTypeRegister) {
      setSelectedTypeRegister(id);
      setSelectedChannel(undefined);
    }
  };

  const onFinish = async (values: any) => {
    if (state.currentRequest?.channel) {
      onRegisterPackage();
    } else {
      onCreateRequest(values.packageCode, selectedTypeRegister);
    }
  };

  const onFinishFailed = (_error: any) => {
    // TODO: handle error
  };

  const onChangeOTP = (e: any) => {
    updateOtp(e?.target?.value ?? '');
  };

  const onChangePackages = (value: string) => {
    setSelectedChannel(value);
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldValue('otp', state.otp);
  }, [selectedTypeRegister, state.openCreateRequest]);

  return (
    <Fragment>
      <PopupHeader phoneNumber={phoneNumber} />
      <SelectOptions
        data={DATA_REGISTER}
        selected={selectedTypeRegister}
        handleOptionChange={handleOptionChange}
      />
      <Form
        name="formRef"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        form={form}
      >
        {state.currentRequest?.channel ? (
          <InputOTP
            time={state.timeCountDown}
            otp={state.otp}
            onSaveOtp={onSaveOtp}
            onEndTime={hiddenModal}
            onChangeOTP={onChangeOTP}
          />
        ) : (
          <Form.Item
            name="packageCode"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn gói cước đăng ký!',
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              notFoundContent="Không có dữ liệu"
              filterOption={(input, option) =>
                (option?.label?.toLowerCase() ?? ('' as any)).includes(
                  input?.toLowerCase()
                )
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? ('' as any))
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? ('' as any)).toLowerCase())
              }
              placeholder={'Gói cước đăng ký'}
              value={selectedChannel}
              onChange={onChangePackages}
              options={phonePackages.map((item: PhonePackage) => ({
                label: item.code,
                value: item.code,
              }))}
            />
          </Form.Item>
        )}

        <div className="flex justify-center">
          <Button type="primary" htmlType="submit" loading={state.loading}>
            {state.currentRequest?.channel ? 'Xác nhận' : 'Tạo yêu cầu'}
          </Button>
        </div>
      </Form>
    </Fragment>
  );
}
