import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

type Props = {
    color: string;
    onChangeColor: (value: string) => void;
};

type State = {
  displayColorPicker: boolean;
  color: any;
};

const SketchPickerCustom = (props: Props) => {
  const [state, setState] = useState<State>({
    displayColorPicker: false,
    color: {
      hex: props.color,
    },
  });

  const handleClick = () => {
    setState((cur: State) => ({
      ...cur,
      displayColorPicker: !cur.displayColorPicker,
    }));
  };

  const handleClose = () => {
    setState((cur: State) => ({ ...cur, displayColorPicker: false }));
  };

  const handleChange = (color: any) => {
    setState((cur: State) => ({ ...cur, color }));
    props.onChangeColor(color.hex);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "25px",
        height: "25px",
        borderRadius: "2px",
        background: state.color?.hex,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        border: '1px solid #d9d9d9', 
        borderRadius: '8px', 
        height: '48px'
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
      text: {
        paddingLeft: "10px"
      }
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
        <div style={styles.text}>{state.color.hex.toString()}</div>
      </div>
      {state.displayColorPicker ? (
        <div style={styles.popover as any}>
          <div style={styles.cover as any} onClick={handleClose} />
          <SketchPicker
            color={state.color}
            onChange={handleChange}
            width={"270px"}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SketchPickerCustom;
