import { Skeleton } from 'antd';
import { useWindowSize } from 'src/utils/customHooks';

function SalesSkeleton() {
  const windowSize = useWindowSize();
  return (
    <div className="mt-[45px]">
      <Skeleton.Input
        active
        size={'large'}
        style={{
          width: windowSize?.width - 40,
          marginTop: 10,
          height: 65,
          borderRadius: 10,
        }}
      />

      <div
        className="flex w-full mb-[10px]"
        style={{ justifyContent: 'center' }}
      >
        <Skeleton.Input
          active
          size={'large'}
          style={{
            width: 600,
            marginTop: 10,
            height: 400,
            borderRadius: 10,
            marginRight: 10,
          }}
        />
        <Skeleton.Input
          active
          size={'large'}
          style={{
            width: 600,
            marginTop: 10,
            height: 400,
            borderRadius: 10,
          }}
        />
      </div>
      <div className="flex w-full mb-5" style={{ justifyContent: 'center' }}>
        <Skeleton.Input
          active
          size={'large'}
          style={{
            width: 600,
            height: 500,
            borderRadius: 10,
            marginBottom: 20,
            marginRight: 10,
          }}
        />
        <Skeleton.Input
          active
          size={'large'}
          style={{
            width: 600,
            height: 500,
            borderRadius: 10,
            marginBottom: 20,
          }}
        />
      </div>
    </div>
  );
}

export default SalesSkeleton;
