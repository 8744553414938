import APIService from "../utils/apiServices";

class LevelSaleServices extends APIService {
  async getLevels() {
    return await this.request("GET", "level-sale");
  }

  async create(params: CreateLevelSaleParams) {
    return await this.request("POST", "level-sale", {
      ...params,
      code: params.code.trim(),
    });
  }

  async edit(id: number, params: CreateLevelSaleParams) {
    return await this.request("PUT", `level-sale/${id}`, params);
  }

  async delete(id: number) {
    return await this.request("DELETE", `level-sale/${id}`);
  }
}

const levelSaleService = new LevelSaleServices();
export default levelSaleService;
