import APIService from '../utils/apiServices';

class TopUpServices extends APIService {
  async getListData(query) {
    return await this.request('GET', `top-up/search${query || ''}`, {});
  }

  async topUp(query) {
    return await this.request('POST', `top-up${query || ''}`, {});
  }

  async exportFile(query) {
    return await this.request(
      'GET',
      `top-up/export${query || ''}`,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  async transactionCreate(params) {
    return await this.request('POST', 'transaction/create', params);
  }
}
const service = new TopUpServices();
export default service;
