import _ from "lodash";

export const getValuesNodeTree = (data: any, selected: string[]) => {
  const newArr: string[] = [];
  selected.forEach((item) => {
    if (item.includes('team_sale_')) {
      data
        .find(
          (staff: SaleManInfo & { value: string; title: string }) =>
            staff.value === item
        )
        ?.children?.forEach((child: any) => {
          newArr.push(child.value);
        });
    } else {
      newArr.push(item);
    }
  });

  return newArr;
};


export const convertReasonsToTreeData = (dataReason: any[]) => {
  return dataReason?.map((item: any) => {
    return {
      title: item.parentLevelSaleCode,
      key: `${item.parentLevelSaleCode}-${_.flatten(
        item.childLevelSales.map((value: any) => {
          return value.reasons.map((value2: any) => value2.reasonId);
        })
      ).join(',')}`,
      value: `${item.parentLevelSaleCode}-${_.flatten(
        item.childLevelSales.map((value: any) => {
          return value.reasons.map((value2: any) => value2.reasonId);
        })
      ).join(',')}`,
      children: item.childLevelSales.map((sub: any) => {
        return {
          title: sub.levelSale,
          key: `${sub.levelSale}-${_.flatten(
            sub.reasons.map((sub2: any) => sub2.reasonId)
          ).join(',')}`,
          value: `${sub.levelSale}-${_.flatten(
            sub.reasons.map((sub2: any) => sub2.reasonId)
          ).join(',')}`,
          children: sub.reasons.map((sub2: any) => {
            return {
              title: sub2.content,
              key: `L-${sub2.reasonId}`,
              value: `L-${sub2.reasonId}`,
            };
          }),
        };
      }),
    };
  });
};

export const convertCareStatusToTreeData = (dataCareStatus: any[]) => {
  return dataCareStatus.map((item: any) => {
    return {
      title: item.code,
      key: `${_.flatten(
        item.childLevelSales.map((value: any) => {
          return value.code;
        })
      ).join(',')},${item.code}`,
      value: `${_.flatten(
        item.childLevelSales.map((value: any) => {
          return value.code;
        })
      ).join(',')},${item.code}`,
      children: item.childLevelSales.map((sub: any) => {
        return {
          title: sub.definition,
          key: `${sub.code}`,
          value: `${sub.code}`,
        };
      }),
    };
  });
};
