import moment, { Moment } from 'moment';

export const convertTimeStamp = (value: Moment) => {
  return Number(moment(value).format('X')) * 1000;
};

export function compareDateTime(dateTime1: Date, dateTime2: Date) {
  const moment1 = moment(dateTime1, 'YYYY-MM-DD HH:mm:ss');
  const moment2 = moment(dateTime2, 'YYYY-MM-DD HH:mm:ss');

  return moment1.isAfter(moment2);
}
