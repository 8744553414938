import React from 'react';
import LevelSaleCode from 'src/components/LevelSale/LevelSaleCode';
import { getLevelSaleColor } from 'src/utils';

const stylesTitle = {
  borderRadius: 0,
  borderLeftWidth: 0,
  width: '100%',
  height: '2vw',
};

const textStyle = {
  fontWeight: 'bold',
  fontSize: 15,
};

export const TabColumn: React.FC<any> = ({ title, levels }: any) => {
  return (
    <LevelSaleCode
      code={title}
      color={getLevelSaleColor(title, levels)}
      style={stylesTitle}
      textStyle={textStyle}
    />
  );
};
